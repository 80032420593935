

















































































































































































































































import { RuleResultModel } from '@/app_code/models/RuleResultModel'
import { API, ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ROICalculator extends Mixins(View) {
  private units = 1
  private assessmentTools = 1
  private pricingData: RuleResultModel[] | null = null

  private mapping = {
    rate: 50,
    hours: 4,
    people: 1
  }

  get mappingCost (): number {
    return (
      this.units * this.mapping.rate * this.mapping.hours * this.mapping.people
    )
  }

  private review = {
    rate: 50,
    hours: 8,
    people: 1
  }

  get reviewCost (): number {
    return (
      this.assessmentTools *
      this.review.rate *
      this.review.hours *
      this.review.people
    )
  }

  private reports = {
    rate: 50,
    hours: 4,
    people: 1
  }

  get reportsCost (): number {
    return (
      this.reports.rate * this.reports.hours * this.reports.people * this.units
    )
  }

  get totalCost (): number {
    return this.mappingCost + this.reviewCost + this.reportsCost
  }

  get totalTime (): number {
    return (
      Math.round(
        parseFloat(String(this.mapping.hours)) +
          parseFloat(String(this.review.hours)) * this.assessmentTools +
          parseFloat(String(this.reports.hours))
      ) * this.units
    )
  }

  moneyFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  })

  pluralise (count: number, singular: string): string {
    return `${count} ${count === 1 ? singular : `${singular}s`}`
  }

  get formattedTime (): string {
    var hours = this.totalTime
    const workingHours = 8
    if (hours > workingHours * 5 * 4 * 12 * 10) {
      return this.pluralise(
        Math.ceil(hours / (workingHours * 5 * 4 * 12 * 10)),
        'Decade'
      )
    }
    if (hours > workingHours * 5 * 4 * 12) {
      return this.pluralise(
        Math.ceil(hours / (workingHours * 5 * 4 * 12)),
        'Year'
      )
    }
    if (hours > workingHours * 5 * 4) {
      return this.pluralise(Math.ceil(hours / (workingHours * 5 * 4)), 'Month')
    }
    if (hours > workingHours * 5) {
      return this.pluralise(Math.ceil(hours / (workingHours * 5)), 'Week')
    }
    if (hours > workingHours) {
      return this.pluralise(Math.ceil(hours / workingHours), 'Day')
    }
    if (hours >= 1) return this.pluralise(Math.ceil(hours), 'Hour')

    return this.pluralise(Math.round(hours * 60), 'Minute')
  }

  get p2sCost (): number {
    return this.calculateComponentPrice(7, this.units) + 0.5 * this.mapping.rate
  }

  get p2sTime (): string {
    var hours = (this.assessmentTools * this.units) / 3

    const workingHours = 8

    if (hours > workingHours * 5 * 4 * 12 * 10) {
      return this.pluralise(
        Math.ceil(hours / (workingHours * 5 * 4 * 12 * 10)),
        'Decade'
      )
    }
    if (hours > workingHours * 5 * 4 * 12) {
      return this.pluralise(
        Math.ceil(hours / (workingHours * 5 * 4 * 12)),
        'Year'
      )
    }
    if (hours > workingHours * 5 * 4) { return this.pluralise(Math.ceil(hours / (workingHours * 5 * 4)), 'Month') }
    if (hours > workingHours * 5) { return this.pluralise(Math.ceil(hours / (workingHours * 5)), 'Week') }
    if (hours > workingHours) { return this.pluralise(Math.ceil(hours / workingHours), 'Day') }
    if (hours >= 1) return this.pluralise(Math.ceil(hours), 'Hour')

    return this.pluralise(Math.round(hours * 60), 'Minute')
  }

  calculateComponentPrice (itemType: number, qty:number): number {
    var total = 0
    var ruleset = this.pricingData?.find((rs: { itemType: number }) => rs.itemType === itemType)

    if (ruleset) {
      if (ruleset.isMarginal) {
        for (var i = 0; i < ruleset.rules.length; i++) {
          var rule = ruleset.rules[i]

          var qtyInRule = Math.max(
            0,
            Math.min(rule.maxQty - rule.minQty, qty - rule.minQty)
          )

          total += qtyInRule * rule.rate
        }
      } else {
        for (var j = 0; j < ruleset.rules.length; j++) {
          var rule2 = ruleset.rules[j]

          if (qty > rule2.maxQty) continue

          total = qty * rule2.rate
          break
        }
      }
    }
    return total
  }

  get savingsEst (): number {
    return this.totalCost - this.p2sCost
  }

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getPricingRules()
  }

  getPricingRules (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.PaymentAPI.GetPricingCalculatorRules()
        .then(data => {
          this.pricingData = data
          resolve(true)
        })
        .catch(reject)
    })
  }
}
